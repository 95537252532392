#global-logo {
  height: 30px;
}

#global-mark {
  height: 100px;
}

#hero {
  display: table;
}

#hero .hero-inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.hero-section.app-hero {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  padding-bottom: 160px;
  color: #fff;
}

.app-hero-content h1,
.app-hero-content p {
  color: #fff;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.login-section h1 {
  color: #666;
}

@media only screen and (min-width: 767px) {
  .login-section .services-content h1 {
    font-size: 24px;
  }
}

.login-section .services-content h1 {
  font-size: 20px;
}

.img {
  background-repeat: no-repeat;
  background-position: center center;
}

.services-icon .img {
  display: inline-block;
  width: 60px;
  height: 60px;
}

.services-icon .img.service_mt {
  background-image: url("../images/SVG/service_mt.svg");
  margin: 2px 3px;
}

.services-icon .img.service_bd {
  background-image: url("../images/SVG/service_bd.svg");
  margin: 6px;
}

.services-icon .img.service_ll {
  background-image: url("../images/SVG/service_ll.svg");
  margin: 4px;
}

.services-icon .img.service_nc {
  background-image: url("../images/SVG/service_nc.svg");
  margin: 6px 4px;
}

.services-icon .img.service_uu {
  background-image: url("../images/SVG/service_uu.svg");
  margin: 4px;
}

.services-icon .img.service_oo {
  background-image: url("../images/SVG/service_oo.svg");
  margin: 4px;
}

.services-icon .img.service_kk {
  background-image: url("../images/SVG/service_kk.svg");
  margin: 4px;
}

.services-icon .img.service_gn {
  background-image: url("../images/SVG/service_gn.svg");
  margin: 4px;
}

.services-icon .img.service_ss {
  background-image: url("../images/SVG/service_ss.svg");
  margin: 4px;
}

.services-icon .img.service_sl {
  background-image: url("../images/SVG/service_sl.svg");
  margin: 4px;
}

.services-icon .img.service_tl {
  background-image: url("../images/SVG/service_tl.svg");
  margin: 4px;
}

.services-icon .img.service_kt {
  background-image: url("../images/SVG/service_kt.svg");
  margin: 4px;
}

.services .col-sm-4:hover .services-icon {
  border-color: #c3ffa5;
}

.philosophy-section {
  position: relative;
  background-color: #193c6d;
  -ms-filter: "progid: DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr='#003073', endColorstr='#029797')";
  background-size: 100%;
  background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0, #003073), color-stop(100%, #029797));
  background-image: -webkit-linear-gradient(135deg, #003073, #029797);
  background-image: -moz-linear-gradient(45deg, #003073, #029797);
  background-image: -ms-linear-gradient(45deg, #003073 0, #029797 100%);
  background-image: -o-linear-gradient(45deg, #003073, #029797);
  background-image: linear-gradient(135deg, #003073, #029797);
  overflow: hidden;
}

.philosophy-section h1,
.philosophy-section p {
  color: #fff;
}

.philosophy-section .philosophy-content {
  margin-top: 200px;
  margin-bottom: 150px;
}

@media only screen and (min-width: 767px) {
  .philosophy-content h1,
  .aboutus-content h1 {
    font-size: 28px;
    line-height: 1.4;
  }
}

.philosophy-content h1,
.aboutus-content h1 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.4;
  margin-bottom: 10px;
}

.philosophy-content p {
  line-height: 1.4;
}

#philosophy-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
}

.services {
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 100px;
}

.aboutus-section {
  padding: 100px 0;
  background: #f3f3f3;
}

.aboutus-section .aboutus-content h1 {
  margin-bottom: 50px;
}

.aboutus-section .greetings {
  text-align: left;
}

.aboutus-section .greetings h2 {
  font-size: 20px;
  margin-bottom: 14px;
}

.aboutus-section .greetings img.portrait {
  display: inline-block;
  margin: 0 6px 4px 16px;
  border-radius: 50%;
  float: right;
}

.aboutus-section .greetings p {
  line-height: 1.6;
  margin-bottom: 1rem;
  text-align: justify;
}

.aboutus-section .company-info .label {
  margin-bottom: .5rem;
}

.aboutus-section .company-info .value {
  margin-bottom: 1rem;
}

#privacy-policy h2 {
  font-size: 130%;
  margin-bottom: 1rem;
}

#privacy-policy h3 {
  margin-top: .5rem;
  font-size: 120%;
  margin-bottom: 1rem;
}

#privacy-policy p,
#privacy-policy ul {
  margin-bottom: 1rem;
}

#privacy-policy p {
  line-height: 1.4;
}

